import { createSlice } from "@reduxjs/toolkit";
import { format, parse, subMonths } from "date-fns";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const dashboardDefault = {
  cardAll: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardSale: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
    dateInitPast: parse(
      format(subMonths(currentDay, 1), "yyyy-MM-01"),
      "yyyy-MM-dd",
      1,
    ),
    dateEndPast: subMonths(currentDay, 1),
  },
  cardPurchase: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
    dateInitPast: parse(
      format(subMonths(currentDay, 1), "yyyy-MM-01"),
      "yyyy-MM-dd",
      1,
    ),
    dateEndPast: subMonths(currentDay, 1),
  },
  cardPisCofins: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardIcms: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },

  cardMiniVendasTotals: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardMiniComprasTotals: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardMiniSkuVendidosTotals: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardDinheiroMesa: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardCuidadoTentacao: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardQueimaCaixa: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardRiscoAssumido: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardReprecificacao: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
  cardProdutosVendidos: {
    dateInit: firstDay,
    dateEnd: currentDay,
    tag: "XML",
  },
};

const handleCardSaleReducer = (state, { payload }) => {
  state.cardSale.dateInit = payload.dateInit;
  state.cardSale.dateEnd = payload.dateEnd;
  state.cardSale.dateInitPast = subMonths(payload.dateInit, 1);
  state.cardSale.dateEndPast = subMonths(payload.dateEnd, 1);
};

const handleCardPurchaseReducer = (state, { payload }) => {
  state.cardPurchase.dateInit = payload.dateInit;
  state.cardPurchase.dateEnd = payload.dateEnd;
  state.cardPurchase.dateInitPast = subMonths(payload.dateInit, 1);
  state.cardPurchase.dateEndPast = subMonths(payload.dateEnd, 1);
};

const handleCardPisCofinsReducer = (state, { payload }) => {
  state.cardPisCofins.dateInit = payload.dateInit;
  state.cardPisCofins.dateEnd = payload.dateEnd;
};

const handleCardIcmsReducer = (state, { payload }) => {
  state.cardIcms.dateInit = payload.dateInit;
  state.cardIcms.dateEnd = payload.dateEnd;
};

const handleCardMiniVendasTotalsReducer = (state, { payload }) => {
  state.cardMiniVendasTotals.dateInit = payload.dateInit;
  state.cardMiniVendasTotals.dateEnd = payload.dateEnd;
};

const handleCardMiniComprasTotalsReducer = (state, { payload }) => {
  state.cardMiniComprasTotals.dateInit = payload.dateInit;
  state.cardMiniComprasTotals.dateEnd = payload.dateEnd;
};

const handleCardMiniSkuVendidosTotalsReducer = (state, { payload }) => {
  state.cardMiniSkuVendidosTotals.dateInit = payload.dateInit;
  state.cardMiniSkuVendidosTotals.dateEnd = payload.dateEnd;
};

const handleCardDinheiroNaMesaReducer = (state, { payload }) => {
  state.cardDinheiroMesa.dateInit = payload.dateInit;
  state.cardDinheiroMesa.dateEnd = payload.dateEnd;
};

const handleCardCuidadoTentacaoReducer = (state, { payload }) => {
  state.cardCuidadoTentacao.dateInit = payload.dateInit;
  state.cardCuidadoTentacao.dateEnd = payload.dateEnd;
};

const handleCardQueimaCaixaReducer = (state, { payload }) => {
  state.cardQueimaCaixa.dateInit = payload.dateInit;
  state.cardQueimaCaixa.dateEnd = payload.dateEnd;
};

const handleCardRiscoAssumidoReducer = (state, { payload }) => {
  state.cardRiscoAssumido.dateInit = payload.dateInit;
  state.cardRiscoAssumido.dateEnd = payload.dateEnd;
};

const handleCardProdutosVendidosReducer = (state, { payload }) => {
  state.cardProdutosVendidos.dateInit = payload.dateInit;
  state.cardProdutosVendidos.dateEnd = payload.dateEnd;
};

const handleCardAllDateReducer = (state, payload) => {
  state.cardAll.dateInit = payload.payload.dateInit;
  state.cardAll.dateEnd = payload.payload.dateEnd;

  handleCardSaleReducer(state, payload);
  handleCardPurchaseReducer(state, payload);
  handleCardPisCofinsReducer(state, payload);
  handleCardIcmsReducer(state, payload);
  handleCardDinheiroNaMesaReducer(state, payload);
  handleCardCuidadoTentacaoReducer(state, payload);
  handleCardQueimaCaixaReducer(state, payload);
  handleCardRiscoAssumidoReducer(state, payload);
  handleCardProdutosVendidosReducer(state, payload);

  handleCardMiniVendasTotalsReducer(state, payload);
  handleCardMiniComprasTotalsReducer(state, payload);
  handleCardMiniSkuVendidosTotalsReducer(state, payload);
};

const initialDashboard = () => {
  const item = window.localStorage.getItem("app-dashboard");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : dashboardDefault;
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboard(),
  reducers: {
    handleCardSale: handleCardSaleReducer,
    handleCardPurchase: handleCardPurchaseReducer,
    handleCardPisCofins: handleCardPisCofinsReducer,
    handleCardIcms: handleCardIcmsReducer,
    handleCardMiniVendasTotals: handleCardMiniVendasTotalsReducer,
    handleCardMiniComprasTotals: handleCardMiniComprasTotalsReducer,
    handleCardMiniSkuVendidosTotals: handleCardMiniSkuVendidosTotalsReducer,
    handleCardDinheiroNaMesa: handleCardDinheiroNaMesaReducer,

    handleCardCuidadoTentacao: handleCardCuidadoTentacaoReducer,

    handleCardQueimaCaixa: handleCardQueimaCaixaReducer,
    handleCardRiscoAssumido: handleCardRiscoAssumidoReducer,
    handleCardProdutosVendidos: handleCardProdutosVendidosReducer,

    handleCardAllDate: handleCardAllDateReducer,
  },
});

export const {
  handleCardSale,
  handleCardPurchase,
  handleCardPisCofins,
  handleCardIcms,
  handleCardMiniComprasTotals,
  handleCardMiniSkuVendidosTotals,
  handleCardMiniVendasTotals,
  handleCardDinheiroNaMesa,
  handleCardCuidadoTentacao,
  handleCardQueimaCaixa,
  handleCardRiscoAssumido,
  handleCardProdutosVendidos,
  handleCardAllDate,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
