import React from "react";

import { ChevronDown } from "react-feather";
import DataTable from "react-data-table-component";

import { ProgressPage } from "components/structure";

import { columns } from "./columns";

import * as S from "../Tabls.styles";

const customStyles = {
  rows: {
    style: {
      padding: "10px 0 10px 0",
    },
  },
};

export const TableListNotes = ({ data: dataNote }) => {
  return (
    <>
      <S.Wrapper borderColor="#6153f6" color="#f1eff6">
        <DataTable
          noHeader
          sortServer
          subHeader={false}
          columns={columns}
          responsive={true}
          customStyles={customStyles}
          data={dataNote?.notas || []}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          defaultSortField="fornecedor"
          progressComponent={<ProgressPage />}
        />
      </S.Wrapper>
    </>
  );
};
