import React from "react";
import DataTableDefault from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { AlertList } from "components/structure/AlertList";
import { Spinner } from "reactstrap";
import { ChevronDown } from "react-feather";

const paginationComponentOptions = {
  rowsPerPageText: "Items por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

const CustomPagination = ({ totalPages, handlePageChange, page: pageItem }) => (
  <ReactPaginate
    nextLabel=""
    breakLabel="..."
    previousLabel=""
    pageCount={totalPages}
    activeClassName="active"
    breakClassName="page-item"
    pageClassName={"page-item"}
    breakLinkClassName="page-link"
    nextLinkClassName={"page-link"}
    pageLinkClassName={"page-link"}
    nextClassName={"page-item next"}
    previousLinkClassName={"page-link"}
    previousClassName={"page-item prev"}
    onPageChange={({ selected }) =>
      handlePageChange && handlePageChange(selected === 0 ? 1 : selected + 1)
    }
    forcePage={pageItem !== 0 ? pageItem - 1 : 0}
    containerClassName={"pagination react-paginate justify-content-end p-1"}
  />
);

const NoData = () => (
  <AlertList
    title="Informativo"
    description="Ainda não há dados cadastrados nessa seção."
  />
);

const ProgressPage = () => (
  <div className="text-center pt-4 pb-4">
    <Spinner />
    <h4>Carregando dados....</h4>
  </div>
);

export const DataTable = ({
  isLoading,
  totalDocs,
  totalPages,
  page = 1,
  data = [],
  pagination = true,
  isError = false,
  paginationServer = true,
  handlePageChange = () => {},
  handlePerRowsChange = () => {},
  onSort = null,
  ...props
}) => (
  <DataTableDefault
    pagination={pagination}
    paginationServer={paginationServer}
    data={data}
    onSort={onSort}
    sortIcon={onSort && <ChevronDown />}
    paginationDefaultPage={page}
    progressPending={isLoading}
    paginationComponentOptions={paginationComponentOptions}
    paginationTotalRows={totalDocs}
    onChangeRowsPerPage={handlePerRowsChange}
    paginationComponent={extraProps => (
      <CustomPagination
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        page={page}
        {...extraProps}
      />
    )}
    noDataComponent={
      isError ? <div>error</div> : !isLoading && !data.length && <NoData />
    }
    progressComponent={<ProgressPage />}
    {...props}
  />
);
