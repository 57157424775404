import { useRef } from "react";
import axios from "axios";
import { getSessionCompanies } from "utils/session";
// import { useToast } from "./useToast";

const AUTHENTICATION_BASE_URL =
  process.env.REACT_APP_NFE_API_XML ?? "https://api-xml.mixfiscal.com.br/api";

export const useNfeAPIAuthenticate = () => {
  const apiRef = useRef(axios.create({ baseURL: AUTHENTICATION_BASE_URL }));

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionCompanies();
    return config;
  });

  // TODO: Adicionar toast automatico quando as requisições falharem!
  apiRef.current.interceptors.response.use(
    response => response,
    error => {
      // console.log(error.response);
      return Promise.reject(error);
    },
  );

  return apiRef.current;
};
