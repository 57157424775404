export const FILE_EXPORT_TYPE = {
  CSV: "csv",
  PDF: "pdf",
  XLS: "xls",
  SPED: "sped",
};

export const FILE_EXPORT_LIST = [
  { type: FILE_EXPORT_TYPE.CSV, label: "CSV" },
  { type: FILE_EXPORT_TYPE.PDF, label: "PDF" },
  { type: FILE_EXPORT_TYPE.XLS, label: "XLS" },
  { type: FILE_EXPORT_TYPE.SPED, label: "SPED" },
];
