import { useEffect } from "react";
import useSWR from "swr";
import { useAuth } from "context/AuthContext";
import { useAPI, useAPIAuthenticate, useAPINFE2 } from "./useAPI";
import { useAPILinkMix } from "./useLinkMixAPI";

export const useFetch = (url, axiosConfigs, config) => {
  const api = useAPI();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      if (config?.method === "POST") {
        const response = await api.post(urlReturn, axiosConfigs);

        return response.data;
      }
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchAuthenticate = (url, axiosConfigs, config) => {
  const api = useAPIAuthenticate();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [tokenCompanies, revalidate, revalidateAll, setRevalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const usePostFetchAuthenticate = (
  url,
  payload,
  axiosConfigs,
  config,
) => {
  const api = useAPIAuthenticate();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    [url, JSON.stringify(payload)],
    async urlReturn => {
      const response = await api.post(urlReturn, payload, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

// TODO: Temporario!!!
export const useFetchNfe = (url, axiosConfigs, config) => {
  const api = useAPINFE2();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

// TODO: Temporario!!!
export const useFetchNfePost = (url, payload, axiosConfigs, config) => {
  const api = useAPINFE2();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    [url, JSON.stringify(payload)],
    async urlReturn => {
      const response = await api.post(urlReturn, payload, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchIntregationPost = (url, payload, axiosConfigs, config) => {
  const api = useAPILinkMix();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    [url, JSON.stringify(payload)],
    async urlReturn => {
      const response = await api.post(urlReturn, payload, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};

export const useFetchIntegration = (url, axiosConfigs, config) => {
  const api = useAPILinkMix();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);

      return response.data;
    },
    config,
  );

  // Reload de todas as APIS com os dados dos clientes selecionados na permissão
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
    }
  }, [tokenCompanies, revalidate, setRevalidateAll, revalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};
