export const AUTH_TOKEN_SESSION = "@mixfiscal:authenticatorToken";
export const AUTH_TOKEN_SESSION_TEMPLATE = "@mixfiscal:templateToken";
export const AUTH_TOKEN_SESSION_COMPANIES = "@mixfiscal:authenticatorCompanies";
export const AUTH_TOKEN_SESSION_TOKEN_BASE =
  "@mixfiscal:authenticatorTokenBase";
export const AUTH_USER_SESSION = "@mixfiscal:user";
export const MODAL_INFO_SHOW = "@modalShow";
export const MODAL_INFO_DISABLED_CLICK = "@modalInfoDisabled";
export const MODAL_INFO = "@modalInfoItems";

export const PRODUCT_FILTER_SESSION = "@mixfiscal:productfiltersession";
export const INVOICE_FILTER_SESSION = "@mixfiscal:invoicefiltersession";

const USER_NAME = "firstName";
const USER_LAST_NAME = "lastName";
const USER_ROLE = "role";
const USER_AVATAR = "avatar";

export const DIAGNOSTICO_XML_COLUMNS = "diagnostico_xml_columns";

export const AUTH_SESSION_GROUP_ACESSS = "@mixfiscal:groupAccess";

export const AUTH_SESSION_COMPANIES = "@mixfiscal:companies";

export const AUTH_SESSION_STORES = "@mixfiscal:stores";

export const getSessionToken = () => localStorage.getItem(AUTH_TOKEN_SESSION);

export const getSessionTemplateToken = () =>
  localStorage.getItem(AUTH_TOKEN_SESSION_TEMPLATE);

export const clearModal = (name = MODAL_INFO_SHOW) =>
  localStorage.removeItem(name);
export const clearModalInfoData = () => localStorage.removeItem(MODAL_INFO);

export const getSessionModalInfo = (name = MODAL_INFO_SHOW) => {
  localStorage.getItem(name);
};
export const getSessionModalInfoDisabled = (
  name = MODAL_INFO_DISABLED_CLICK,
) => {
  localStorage.getItem(MODAL_INFO_DISABLED_CLICK);
};
export const getSessionModalData = MODAL_INFO => {
  localStorage.getItem(MODAL_INFO);
};

export const setSessionModalInfo = isShow => {
  localStorage.setItem(MODAL_INFO_SHOW, isShow);
};
export const setSessionModalInfoOnClick = isShow => {
  localStorage.setItem(MODAL_INFO_DISABLED_CLICK, isShow);
};
export const setSessionModalInfoData = data => {
  localStorage.setItem(MODAL_INFO, JSON.stringify(data));
};

export const getSessionCompanies = () =>
  localStorage.getItem(AUTH_TOKEN_SESSION_COMPANIES);

export const getSessionTokenBase = () =>
  localStorage.getItem(AUTH_TOKEN_SESSION_TOKEN_BASE);

export const getSessionUser = () =>
  JSON.parse(localStorage.getItem(AUTH_USER_SESSION));

export const setSessionToken = token =>
  localStorage.setItem(AUTH_TOKEN_SESSION, token);

export const retrieveUserSession = () => {
  try {
    return {
      firstName: localStorage.getItem(USER_NAME),
      lastName: localStorage.getItem(USER_LAST_NAME),
      avatar: localStorage.getItem(USER_AVATAR),
      role: localStorage.getItem(USER_ROLE),
    };
  } catch (e) {
    return {
      firstName: "",
      lastName: "",
      role: "",
      avatar: "",
    };
  }
};

export const setSessionFilters = (name = INVOICE_FILTER_SESSION, filters) =>
  localStorage.setItem(name, JSON.stringify(filters));

export const getFilterSession = (name = INVOICE_FILTER_SESSION) =>
  localStorage.getItem(name);

export const setSessionProductFilter = filters =>
  localStorage.setItem(PRODUCT_FILTER_SESSION, JSON.stringify(filters));

export const getProductFilterSession = (name = PRODUCT_FILTER_SESSION) =>
  localStorage.getItem(name);

export const setSessionInvoiceFilter = filters =>
  localStorage.setItem(INVOICE_FILTER_SESSION, JSON.stringify(filters));

export const getInvoiceFilterSession = (name = INVOICE_FILTER_SESSION) =>
  localStorage.getItem(name);

export const removeClaims = () => localStorage.clear();

export const isAuthenticated = () => getSessionToken() !== null;

export const setSession = (name, value) =>
  localStorage.setItem(name, JSON.stringify(value));

export const getSession = (
  name = DIAGNOSTICO_XML_COLUMNS,
  defaultValue = [],
) => {
  try {
    if (!localStorage.getItem(name)) {
      return defaultValue;
    }
    return JSON.parse(localStorage.getItem(name));
  } catch (err) {
    console.log(err);
    return defaultValue;
  }
};

export const getSessionRaw = (name = DIAGNOSTICO_XML_COLUMNS) =>
  localStorage.getItem(name);

export const setColumns = (columns, name = DIAGNOSTICO_XML_COLUMNS) =>
  setSession(name, columns);

export const getColumns = (name = DIAGNOSTICO_XML_COLUMNS, defaultValue = []) =>
  getSession(name, defaultValue);
