import styled, { css } from "styled-components";

const modifiersBlockUi = {
  isFullHeight: () => css`
    height: 100%;
  `,
};

export const BlockUi = styled.div`
  ${({ isFullHeight, isDisabledAbsolut = false }) => css`
    position: relative;

    &.show {
      .overlay {
        content: "";
        position: ${!isDisabledAbsolut ? "absolute" : ""};
        width: 100%;
        height: 100%;
        top: 0;
        background-color: #ffffff;
        border-radius: 0.428rem;
      }

      > :first-child,
      code {
        background-color: initial;
      }
    }

    .loader {
      top: 50%;
      left: 50%;
      display: flex;
      position: absolute;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      transform: translate(-50%, -50%);
    }

    ${isFullHeight && modifiersBlockUi.isFullHeight()}
  `}
`;

export const BlockUiContent = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h6 {
    margin-top: 8px;
  }
`;

export const BlockBlur = styled.div`
  filter: blur(2px);
`;
