import { createSlice } from "@reduxjs/toolkit";

export const initialStores = {
  list: [],
  params: {
    companyName: "",
    cnpj: "",
    city: "",
    page: 1,
    limit: 20,
  },
  totalPages: 0,
};

const initialState = () => {
  return initialStores;
};

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.page = payload.page;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.params.page = payload.page;
      }
      if (payload.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetPageAndLimit,
  handleParamsLimit,
} = storesSlice.actions;

export default storesSlice.reducer;
