import { createSlice } from "@reduxjs/toolkit";
import { companiesByStores, storesUniqs } from "utils/context/store";
import {
  handleSelectCompaniesReducer,
  handleSelectStoreReducer,
  handleSelectStoreClearReducer,
  handleSelectCompaniesClearReducer,
} from "./app/reducers";

const LOCAL_NAME = "app-headerData";

const initialHeader = () => {
  const headerData = window.localStorage.getItem(LOCAL_NAME)
    ? JSON.parse(window.localStorage.getItem(LOCAL_NAME))
    : {};

  return {
    accessGroupOpen: false,
    companiesOpen: false,
    storesOpen: false,
    search: false,
    accessGroup: null,
    companies: [],
    companiesSelecteds: [],
    stores: [],
    storesSelecteds: [],
    companiesSearchSelecteds: [],
    ...headerData,
  };
};

export const setHeaderData = (values = {}) =>
  window.localStorage.setItem(LOCAL_NAME, JSON.stringify(values));

export const appSlice = createSlice({
  name: "app",
  initialState: {
    header: initialHeader(),
  },
  reducers: {
    handleHeaderGroup: (state, action) => {
      state.header.accessGroup = action.payload._id;
      setHeaderData(state.header);
    },
    handleSelectCompanies: handleSelectCompaniesReducer,

    handleSelectStore: handleSelectStoreReducer,
    handleHeaderGroupClose: () => {},
    handleHeaderAccessGroup: (state, action) => {
      state.header.accessGroupOpen = action.payload;
      setHeaderData(state.header);
    },
    handleHeaderCompanies: (state, action) => {
      state.header.companiesOpen = action.payload;
      setHeaderData(state.header);
    },
    handleHeaderStores: (state, action) => {
      state.header.storesOpen = action.payload;
      setHeaderData(state.header);
    },
    handleHeaderSearch: (state, action) => {
      state.header.search = action.payload;
    },

    handleCompaniesSearchSelecteds: (state, action) => {
      const { stores } = action.payload;

      state.header.companiesSearchSelecteds = stores;
    },

    handleCompaniesSearchSubmit: (state, action) => {
      const { companiesSearchSelecteds } = action.payload;

      const companies = companiesByStores(companiesSearchSelecteds);
      const stores = storesUniqs(companiesSearchSelecteds);

      handleSelectCompaniesClearReducer(state);
      for (const company of companies) {
        handleSelectCompaniesReducer(state, {
          payload: company,
        });
      }

      handleSelectStoreClearReducer(state);
      for (const store of stores) {
        handleSelectStoreReducer(state, {
          payload: store,
        });
      }

      state.header.search = false;
      state.header.companiesSearchSelecteds = [];

      setHeaderData(state.header);
    },

    handleSyncAfterLogin: state => {
      state.header = initialHeader();
    },
  },
});

export const {
  handleHeaderGroup,
  handleHeaderGroupClose,
  handleHeaderAccessGroup,
  handleHeaderSearch,
  handleSelectCompanies,
  handleSelectStore,
  handleHeaderCompanies,
  handleHeaderStores,
  handleCompaniesSearchSelecteds,
  handleCompaniesSearchSubmit,
  handleSyncAfterLogin,
} = appSlice.actions;

export default appSlice.reducer;
