import { storesUniqs } from "utils/context/store";

const LOCAL_NAME = "app-headerData";

const setHeaderData = (values = {}) =>
  window.localStorage.setItem(LOCAL_NAME, JSON.stringify(values));

export const handleSelectCompaniesReducer = (state, action) => {
  const { _id, stores } = action.payload;

  const res = state.header.companies.find(company => company._id === _id);

  if (!res) {
    state.header.companies.push(action.payload);

    state.header.stores = storesUniqs([
      ...(stores ?? []),
      ...state.header.stores,
    ]);

    setHeaderData(state.header);
    return state;
  }

  res?.stores?.forEach(store => {
    state.header.stores.splice(
      state.header.stores.findIndex(s => s._id === store._id),
      1,
    );
    state.header.storesSelecteds.splice(
      state.header.storesSelecteds.findIndex(s => s._id === store._id),
      1,
    );
  });

  state.header.companies.splice(state.header.companies.indexOf(res), 1);

  setHeaderData(state.header);
};

export const handleSelectStoreReducer = (state, action) => {
  const { _id } = action.payload;

  const res = state.header.storesSelecteds.find(store => store._id === _id);

  if (!res) {
    state.header.storesSelecteds.push(action.payload);
    setHeaderData(state.header);
    return state;
  }

  state.header.storesSelecteds.splice(
    state.header.storesSelecteds.indexOf(res),
    1,
  );

  setHeaderData(state.header);
};

export const handleSelectCompaniesClearReducer = state => {
  state.header.companies = [];
};

export const handleSelectStoreClearReducer = state => {
  state.header.storesSelecteds = [];
};
