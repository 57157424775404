import { createSlice } from "@reduxjs/toolkit";

const initialRelatorioCfop = {
  tipoMovimento: "",
  page: 50,
  isShowFilter: false,
};

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const initialState = () => {
  return {
    ...initialRelatorioCfop,
    issueDateStart: firstDay,
    issueDateEnd: currentDay,
  };
};

export const relatorioCfopSlice = createSlice({
  name: "relatorioCfopCsts",
  initialState,
  reducers: {
    handleChangeDate: (state, { payload }) => {
      state.issueDateStart = payload.issueDateStart;
      state.issueDateEnd = payload.issueDateEnd;
    },
    handleChangeMovement: (state, { payload }) => {
      state.tipoMovimento = payload;
    },
    handlePageAndLimit: (state, { payload }) => {
      state.page = payload;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
  },
});

export const {
  handleChangeDate,
  handleChangeMovement,
  handlePageAndLimit,
  handleToogleFilters,
} = relatorioCfopSlice.actions;

export default relatorioCfopSlice.reducer;
