import React, { Fragment } from "react";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { Format } from "utils";

export const Date = ({ value, handleOnChange }) => {
  return (
    <Fragment>
      <div className="d-flex align-items-center me-1">
        <label htmlFor="rows-per-page">Data</label>
        <input type="hidden" className="date" name="mother" />
        <Flatpickr
          options={{
            locale: Portuguese,
            mode: "range",
            dateFormat: "Y-m-d",
            formatDate: value => {
              return value > 0
                ? Format(value, "dd/MM/yyyy")
                : "Carregando data...";
            },
          }}
          style={{
            width: "100%",
            color: "#7367f0",
          }}
          className="form-control ms-50 pe-3"
          value={value}
          onChange={date => handleOnChange(date)}
          id="default-picker"
        />
      </div>
    </Fragment>
  );
};
