import { createSlice } from "@reduxjs/toolkit";

export const initialNcm = {
  list: [],
  params: {
    ncm: "",
    page: 1,
    limit: 20,
  },
  type: "",
  totalPages: 0,
};

const initialState = () => {
  return initialNcm;
};

export const ncmSlice = createSlice({
  name: "ncm",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      console.log("aqui", state.page);
      state.page = payload.page;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.params.page = payload.page;
      }
      if (payload.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetPageAndLimit,
  handleParamsLimit,
} = ncmSlice.actions;

export default ncmSlice.reducer;
