import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { handleParamsStartEnd } from "redux/taxAnalises";

import { Date } from "components/contexts/TaxAnalysis/List/Header/Filter/Date";
import { ActionsExport } from "components/contexts/TaxAnalysis/List/Header/ActionsExports";

import { Row, Col } from "reactstrap";

export const CustomHeader = () => {
  const { issue_date_end: end, issue_date_start: start } = useSelector(
    state => state.taxAnalises,
  );

  const dispatch = useDispatch();

  const handleOnChange = value => {
    if (value[0] && value[1]) {
      dispatch(
        handleParamsStartEnd({
          issue_date_end: value[1] ? format(value[1], "yyyy-MM-dd") : null,
          issue_date_start: format(value[0], "yyyy-MM-dd"),
        }),
      );
    }
  };

  return (
    <div className="invoice-list-table-header w-100 py-2">
      <Row>
        <Col md="12">
          <Row>
            <Col md="5">
              <h3>Produtos</h3>
            </Col>
            <Col md="7 text-end">
              <div className="d-flex justify-content-end">
                <Date handleOnChange={handleOnChange} value={[start, end]} />
                <ActionsExport />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
