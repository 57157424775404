import React, { useState } from "react";

import { Badge, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import { formatCurrency, limitCaracter } from "utils";

import * as S from "./Products.styles";

import FireIcon from "assets/icone-fire.png";
import RiskIcon from "assets/icone-risk.png";

const LIMIT_ICON = 5;

const percente = value =>
  Number(value / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });

const PopoverItems = ({ title, titleCard, id, limit = 35 }) => {
  const [isShowPopover, setIsShowPopover] = useState("");
  const PopoverId = `Popover-${id}`;

  const OpenPopover = () =>
    setIsShowPopover(isShowPopover === PopoverId ? "" : PopoverId);

  return title?.length > 20 ? (
    <>
      <span
        onMouseEnter={() => OpenPopover()}
        onMouseLeave={() => setIsShowPopover("")}
        id={PopoverId}
      >
        {limitCaracter(limit, title)}
      </span>
      <Popover
        isOpen={Boolean(isShowPopover)}
        placement="top"
        toogle={OpenPopover}
        target={PopoverId}
      >
        <PopoverHeader>{titleCard}</PopoverHeader>
        <PopoverBody>{title}</PopoverBody>
      </Popover>
    </>
  ) : (
    title
  );
};

export const columns = [
  {
    name: "",
    width: "50px",
    isType: "queima",
    cell: row => {
      const value = row.queima_caixa_icms + row.queima_caixa_pis_cofins;
      return row?.key < 5 && value > LIMIT_ICON ? (
        <S.IconeCustom src={FireIcon} />
      ) : null;
    },
  },
  {
    name: "",
    width: "50px",
    isType: "risco",
    cell: row => {
      return row?.key < 5 ? <S.IconeCustom src={RiskIcon} /> : null;
    },
  },
  {
    name: "Produto",
    sortable: true,
    typesDefaults: [],
    sortField: "xprod",
    minWidth: "320px",
    position: 1,
    cell: row => {
      return (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <h5 className="user-name pt-1">
              {!row.xprod && <Badge>SEM DESCRIÇÃO DO PRODUTO</Badge>}
              {PopoverItems({
                titleCard: "Descrição",
                title: row.xprod,
                limit: 25,
                id: row?.key,
              })}
            </h5>
            <div
              className="user-name pb-1"
              style={{
                gridGap: 2,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Badge color="light-secondary">Cod. Interno: {row.cprod}</Badge>
              {row?.cean && (
                <Badge color="light-secondary">EAN: {row?.cean}</Badge>
              )}
              &nbsp;
              {row?.ncm && (
                <Badge color="light-secondary">NCM: {row?.ncm}</Badge>
              )}
              &nbsp;
              {row?.cest && (
                <Badge color="light-secondary">CEST: {row?.cest}</Badge>
              )}
              &nbsp;
              {row?.cfop && (
                <Badge color="light-secondary">CFOP: {row?.cfop}</Badge>
              )}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    name: "QUEIMA DE CAIXA ICMS",
    sortable: true,
    typesDefaults: [],
    sortField: "cean",
    minWidth: "160px",
    isType: "queima",
    position: 1,
    cell: row => {
      return formatCurrency(row?.queima_caixa_icms);
    },
  },
  {
    name: "QUEIMA DE CAIXA PIS/COFINS",
    sortable: true,
    typesDefaults: [],
    sortField: "cean",
    minWidth: "160px",
    isType: "queima",
    position: 1,
    cell: row => {
      return formatCurrency(row?.queima_caixa_pis_cofins);
    },
  },
  {
    name: "Risco Assumido ICMS",
    sortable: true,
    typesDefaults: [],
    sortField: "cean",
    minWidth: "160px",
    isType: "risco",
    position: 1,
    cell: row => {
      return formatCurrency(row?.risco_assumido_icms);
    },
  },
  {
    name: "Risco Assumido PIS/COFINS",
    sortable: true,
    typesDefaults: [],
    sortField: "cean",
    minWidth: "160px",
    isType: "risco",
    position: 1,
    cell: row => {
      return formatCurrency(row?.risco_assumido_pis_cofins);
    },
  },

  {
    name: "Valor do Produto",
    sortable: true,
    typesDefaults: [],
    sortField: "vprod",
    minWidth: "150px",
    position: 1,
    cell: row => {
      return formatCurrency(row?.vprod);
    },
  },
  // {
  //   name: "Valor PIS",
  //   sortable: false,
  //   typesDefaults: [],
  //   sortField: "valor_pis",
  //   minWidth: "150px",
  //   position: 1,
  //   cell: row => {
  //     return formatCurrency(row?.valor_pis);
  //   },
  // },
  // {
  //   name: "Valor COFINS",
  //   sortable: false,
  //   typesDefaults: [],
  //   sortField: "valor_cofins",
  //   minWidth: "150px",
  //   position: 1,
  //   cell: row => {
  //     return formatCurrency(row?.valor_cofins);
  //   },
  // },
  // {
  //   name: "Valor ICMS",
  //   sortable: true,
  //   typesDefaults: [],
  //   sortField: "valor_icms",
  //   minWidth: "150px",
  //   position: 1,
  //   cell: row => {
  //     return formatCurrency(row?.valor_icms);
  //   },
  // },
  {
    name: "Porcentagem de Queima de Caixa ICMS por período",
    sortable: true,
    typesDefaults: [],
    isType: "queima",
    sortField: "porcentagem_queima_caixa_icms_no_periodo",
    minWidth: "160px",
    position: 1,
    cell: row => {
      return `${percente(row?.porcentagem_queima_caixa_icms_no_periodo)}`;
    },
  },
  {
    name: "Porcentagem de Queima de Caixa PIS/COFINS por período",
    sortable: true,
    typesDefaults: [],
    isType: "queima",
    sortField: "porcentagem_queima_caixa_pis_cofins_no_periodo",
    minWidth: "160px",
    position: 1,
    cell: row => {
      return `${percente(row?.porcentagem_queima_caixa_pis_cofins_no_periodo)}`;
    },
  },
  {
    name: "Porcentagem de Risco de Assumido ICMS por período",
    sortable: true,
    typesDefaults: [],
    isType: "risco",
    sortField: "porcentagem_risco_assumido_icms_no_periodo",
    minWidth: "150px",
    position: 1,
    cell: row => {
      return `${percente(row?.porcentagem_risco_assumido_icms_no_periodo)}`;
    },
  },
  {
    name: "Porcentagem de Risco de Assumido PIS/COFINS por período",
    sortable: true,
    typesDefaults: [],
    isType: "risco",
    sortField: "porcentagem_risco_assumido_pis_cofins_no_periodo",
    minWidth: "150px",
    position: 1,
    cell: row => {
      return `${percente(
        row?.porcentagem_risco_assumido_pis_cofins_no_periodo,
      )}`;
    },
  },
];
