import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setColor: item => css`
    background-color: ${item} !important;
  `,
  setBorderColor: item => css`
    border: 1px solid ${item} !important;
  `,
};

export const Wrapper = styled.div`
  ${({ color, borderColor }) => css`
    ${color && columnFormItemModifiers.setColor(color)}
    ${borderColor && columnFormItemModifiers.setBorderColor(borderColor)}
  `}
`;

export const SubList = styled.div`
  ${({ borderColor }) => css`
    ${borderColor && columnFormItemModifiers.setBorderColor(borderColor)}
  `}
`;
