import { createSlice } from "@reduxjs/toolkit";

export const initialStoreForms = {
  list: [],
  params: {
    companyName: "",
    cnpj: "",
    city: "",
    page: 1,
    limit: 20,
  },

  totalPages: 0,
  filds: {},
  fildsCep: {},
  fildsConfig: {},
  fildsContact: {},
  selectedSpecialTaxRegimes: {},
  listSelectedUsersWallet: [],
};

const initialState = () => {
  return initialStoreForms;
};

export const storesFormsSlice = createSlice({
  name: "storesForms",
  initialState,
  reducers: {
    handleAddNewSpecialTaxRegime: (state, { payload }) => {
      state.selectedSpecialTaxRegimes = {
        ...state.selectedSpecialTaxRegimes,
        [payload?.newTaxRegime?.id_law]: payload?.newTaxRegime,
      };
    },
    handleRemoveSpecialTaxRegime: (state, { payload }) => {
      const newList = Object.values(state.selectedSpecialTaxRegimes)
        .filter(specialTaxTegime => specialTaxTegime.id_law !== payload?.id)
        .reduce((a, v) => ({ ...a, [v.id_law]: v }), {});
      state.selectedSpecialTaxRegimes = { ...newList };
    },
    handleAddAssessores: (state, { payload }) => {
      state.listSelectedUsersWallet = payload;
    },
    handleAddCertificate: (state, { payload }) => {
      state.certificate = payload?.certificate;
    },
    handleFieldForms: (state, { payload }) => {
      if (payload?.fild) {
        state.filds = payload.fild;
      }
      if (payload?.fildCep) {
        state.fildsCep = payload.fildCep;
      }

      if (payload?.config) {
        state.fildsConfig = payload?.config;
      }

      if (payload?.contact) {
        state.fildsContact = payload.contact;
      }
    },
  },
});

export const {
  handleAddAssessores,
  handleAddNewSpecialTaxRegime,
  handleRemoveSpecialTaxRegime,
  handleAddCertificate,
  handleFieldForms,
} = storesFormsSlice.actions;

export default storesFormsSlice.reducer;
