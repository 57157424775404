import { createSlice } from "@reduxjs/toolkit";

export const initialStateAssessores = {
  isOpenModal: false,
  isSearching: false,
  modal: {
    id: "",
  },
  params: {
    page: 1,
    limit: 100,
  },
  totalPages: 0,
  listSelected: [],
};

const initialState = () => {
  return initialStateAssessores;
};

export const assessoresSlice = createSlice({
  name: "assessores",
  initialState,
  reducers: {
    handleSetTotalPages: (state, { payload }) => {
      state.totalPages = payload?.totalPages;
    },
    handleSeOpenModal: (state, { payload }) => {
      state.isOpenModal = payload.isOpenModal;
      state.modal.id = payload?.id;
    },
    handleSetModalId: (state, { payload }) => {
      state.modal.id = payload?.id;
    },
    handleSearchCli: (state, { payload }) => {
      console.log({ payload });
      state.isSearching = payload.isSearching;
    },
    handleSetListSelected: (state, { payload }) => {
      state.listSelected = payload;
    },
    handleParamsLimit: (state, { payload }) => {
      console.log({ payload });
      state.params.limit = payload.limit;
    },
  },
});

export const {
  handleSeOpenModal,
  handleSearchCli,
  handleSetListSelected,
  handleSetTotalPages,
  handleParamsLimit,
  handleSetModalId,
} = assessoresSlice.actions;

export default assessoresSlice.reducer;
