// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import todo from "@src/views/apps/todo/store";
import chat from "@src/views/apps/chat/store";
import email from "@src/views/apps/email/store";
import kanban from "@src/views/apps/kanban/store";
import calendar from "@src/views/apps/calendar/store";
import ecommerce from "@src/views/apps/ecommerce/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/roles-permissions/store";
import app from "./app";
import dashboard from "./dashboard";
import product from "./product";
import productsClient from "./productClients";
import productsRelatory from "./productsRelatory";
import invoice from "./invoice";
import repricing from "./repricing";
import oportunidade from "./oportunidade";
import cest from "./cest";
import ncm from "./ncm";
import stores from "./stores";
import upload from "./Upload";
import users from "./users";
import accessGroups from "./accessGroups";
import dialogAlert from "./DialogAlert/dialog";
import alertness from "./alertness";
import storeStatus from "./Stores/status";
import taxAnalises from "./taxAnalises";
import internoListaClientes from "./interno/lista-clientes";
import storesForms from "./Stores/storesForms";
import classificar from "./classificar";
import relatorioSkus from "./relatorioSkus";
import relatorioCfopCsts from "./relatorioCfop";
import politicPrice from "./politicScreen";
import relatorioDocumentosFiscais from "./Relatorio/documentosFiscais";

import assessores from "./assessores";

const rootReducer = {
  auth,
  todo,
  cest,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  app,
  dashboard,
  product,
  productsClient,
  invoice,
  repricing,
  oportunidade,
  upload,
  users,
  ncm,
  accessGroups,
  stores,
  dialogAlert,
  alertness,
  storeStatus,
  productsRelatory,
  taxAnalises,
  internoListaClientes,
  storesForms,
  assessores,
  classificar,
  relatorioSkus,
  relatorioCfopCsts,
  politicPrice,
  relatorioDocumentosFiscais,
};

export default rootReducer;
