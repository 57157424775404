import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "reactstrap";

import { ChevronDown } from "react-feather";
import DataTable from "react-data-table-component";

import { AlertList, ProgressPage } from "components/structure";

import { handleSetColumnsProducts } from "redux/taxAnalises";

import { columns } from "./columns";

import { TableListNotes } from "../Notes";

import { CustomHeader } from "../../Header/CustomHeader";

const customStyles = {
  rows: {
    style: {
      padding: "10px 0 10px 0",
    },
  },
};

export const ListProducts = ({ data, isLoading }) => {
  const { columnsProducts, tipo } = useSelector(state => state.taxAnalises);

  const dispatch = useDispatch();

  useEffect(() => {
    if (tipo) {
      const items = (columns || []).filter(
        item => item?.isType === tipo || !item?.isType,
      );
      dispatch(handleSetColumnsProducts([...items]));
    }
  }, [tipo, dispatch]);

  return (
    <>
      <Card>
        <DataTable
          noHeader
          pagination={false}
          sortServer
          paginationServer
          responsive={true}
          progressPending={isLoading}
          subHeader={true}
          columns={columnsProducts}
          customStyles={customStyles}
          data={(data || []).map((item, key) => ({
            ...item,
            key,
          }))}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          defaultSortField="invoiceId"
          progressComponent={<ProgressPage />}
          subHeaderComponent={
            <CustomHeader limit={[10, 20, 50, 60, 80, 100, 120]} />
          }
          noDataComponent={
            <AlertList
              title="Não tem produto para essa nota do fornecedor"
              description=""
            />
          }
          expandableRows
          expandableRowExpanded={row => row?.defaultExpanded}
          expandableRowsComponent={TableListNotes}
        />
      </Card>
    </>
  );
};
