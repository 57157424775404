export const operator = [
  {
    label: "Igual",
    value: "equals",
  },
  {
    label: "Contém",
    value: "contains",
  },
  {
    label: "Não Contém",
    value: "notContains",
  },
  {
    label: "termina com",
    value: "endsWith",
  },
  {
    label: "Vazio",
    value: "empty",
  },
  {
    label: "Não Vazio",
    value: "notEmpty",
  },
];

export const operatorOR = [
  {
    label: "E",
    value: "and",
  },
  {
    label: "OU",
    value: "or",
  },
];
