import React from "react";

import { useSkin } from "@hooks/useSkin";

import * as S from "./AlertList.styles";

import "@styles/base/pages/page-misc.scss";

export const AlertList = ({ title, description, isImg = true }) => {
  const { skin } = useSkin();

  const illustration =
      skin === "dark" ? "under-maintenance-dark.svg" : "under-maintenance.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default;

  return (
    <S.CardErrorPage>
      <div className="w-100 text-center">
        <h2 className="mb-1">{title}</h2>
        <p className="mb-2">{description}</p>
        {isImg && <img className="img-fluid" src={source} alt={title} />}
      </div>
    </S.CardErrorPage>
  );
};
