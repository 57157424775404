import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialRepricing = {
  list: [],
  params: {},
  type: "",
  order: {
    column: "chave",
    type: "desc",
  },
  currentMonth: [],
  totalPages: 0,
  isShowFilter: false,
  showBookKeeping: "",
  bookkeping: {},
  bookkepingListItens: [],
  listTabs: [],
  tabSelected: {
    label: "ORIGINAL",
    value: "origin",
    isChecked: true,
  },
  isShowDialog: {
    show: false,
    id: "",
    data: {},
    modelo: "",
  },
  columns: [],
  filters: [],
};

const initialState = () => {
  return initialRepricing;
};

export const repricingSlice = createSlice({
  name: "repricing",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
    },
    handleSetData: (state, { payload }) => {
      state.isShowDialog.data = payload?.data;
    },
    handleParams: (state, { payload }) => {
      state.params = payload;
    },
    handleSetType: (state, { payload }) => {
      state.params.tipo_carga = payload.tipo_carga;
    },
    handleSetProdName: (state, { payload }) => {
      state.params.prod_name = payload.prod_name;
    },
    handleSetProdCean: (state, { payload }) => {
      state.params.prod_cean = payload.prod_cean;
    },
    handleSetProdCProd: (state, { payload }) => {
      state.params.prod_cprod = payload.prod_cprod;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.params.page = payload.page;
      }
      if (payload.limit) {
        state.params.limit = payload.limit;
      }
      if (payload.prod_name) {
        state.params.prod_name = [...payload.prod_name];
      }
      if (payload.type) {
        state.type = payload.type;
      }
      if (payload.totalPages) {
        state.totalPages = payload.totalPages;
      }
    },
    handleColumns: (state, { payload }) => {
      state.columns = payload;
    },
    handleOnChangeFilter: (state, { payload }) => {
      const list = [...state.filters];
      list[payload?.key].columnField[payload?.field] = payload?.value;
      state.filters = list;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
    handleToogleDialog: (state, { payload }) => {
      state.isShowDialog.show = payload.show;
      state.isShowDialog.id = payload.id;
      state.isShowDialog.data = payload.data;
      state.isShowDialog.modelo = payload.modelo;
    },
    handleAddFilters: (state, { payload }) => {
      state.filters = payload;
    },
    handleRemoveFilterUnic: (state, action) => {
      const { id } = action.payload;
      state.filters = state.filters.filter(item => item.id !== id);
    },
    handleBookkepingItem: (state, { payload }) => {
      if (payload.bookkepingListItens) {
        state.bookkepingListItens = payload.bookkepingListItens;
      }

      if (payload.bookkeping) {
        state.bookkeping = payload;
      }
    },
    handleBookKeeping: (state, { payload }) => {
      state.showBookKeeping = payload;
    },
    handleRemoveFilterAll: state => {
      state.filters = [
        {
          id: uuidv4(),
          columnField: {
            columnOrField: "and",
            columnField: "prod_nome",
            operatorValue: "equals",
            value: "",
          },
        },
      ];
    },
  },
});

export const {
  handleTabs,
  handleSetList,
  handleToogleFilters,
  handleOnChangeFilter,
  handleColumns,
  handleSetProdName,
  handleSetProdCProd,
  handleSetProdCean,
  handleAddFilters,
  handleRemoveFilterUnic,
  handleRemoveFilterAll,
  handleSetPageAndLimit,
  handleSetData,
  handleSetType,
  handleParams,
} = repricingSlice.actions;

export default repricingSlice.reducer;
