import styled from "styled-components";
import { DropdownToggle } from "reactstrap";

export const DropdownToggleCustom = styled(DropdownToggle)`
  border-radius: 5px !important;
`;

export const CustomIcone = {
  marginRight: 7,
  marginBottom: -2,
};

export const CustomIconeDownload = {
  width: "18px",
  height: "18px",
  marginRight: 8,
  marginLeft: -3,
};
