import React from "react";
import { CardText, ListGroup, ListGroupItem } from "reactstrap";
import classNames from "classnames";

import { TrendingUp } from "react-feather";
import Avatar from "@components/avatar";

import { formatCurrency } from "../../../../../../utils";

export const CardListing = ({ type, typeActive, items, handleClick }) => (
  <ListGroup horizontal>
    {(items || []).map((item, key) => {
      const active = item.type === typeActive;
      return (
        <ListGroupItem
          key={`${type}-card-list-${key}-${item.color}`}
          active={active}
          onClick={() => handleClick && handleClick(item)}
          style={{ cursor: "pointer" }}
        >
          <div
            className="d-flex align-items-center"
            style={{ width: type === "queima" ? "250px" : "auto" }}
          >
            <Avatar
              color={item?.color}
              icon={<TrendingUp />}
              className="me-2"
            />
            <div className="my-auto">
              <h4
                className="fw-bolder mb-0"
                style={active ? { color: "#fff" } : {}}
              >
                {formatCurrency(item?.total)}
              </h4>
              <CardText className={classNames("font-small-3 mb-0")}>
                {item?.title}
              </CardText>
            </div>
          </div>
        </ListGroupItem>
      );
    })}
  </ListGroup>
);
