import { createSlice } from "@reduxjs/toolkit";

// Retirar a leitura do JWT do front-end
import useJwt from "@src/auth/jwt/useJwt";

import { AUTH_USER_SESSION } from "utils";

import { setHeaderData } from "./app";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem(AUTH_USER_SESSION);
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      const { user, accessGroup, companies, stores } = action.payload;
      state.userData = user;

      state[config.storageTokenKeyName] = "irineu";
      state[config.storageRefreshTokenKeyName] = "irineu";
      localStorage.setItem("userData", JSON.stringify(state.userData));

      if (!Array.isArray(accessGroup) && !user.is_mix) {
        setHeaderData({
          accessGroup: accessGroup?._id,
          companies,
          storesSelecteds: stores,
        });
      }

      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify("irineu"),
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify("irineu"),
      );
    },
    handleLogout: state => {
      localStorage.clear();
      state.userData = {};
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
