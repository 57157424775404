import { createSlice } from "@reduxjs/toolkit";

export const initialAccessGroups = {
  list: [],
  params: {
    groupName: "",
    page: 1,
    limit: 20,
  },
  totalPages: 0,
};

const initialState = () => {
  return initialAccessGroups;
};

export const accessGroupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.page = payload.page;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.params.page = payload.page;
      }
      if (payload.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetPageAndLimit,
  handleParamsLimit,
} = accessGroupsSlice.actions;

export default accessGroupsSlice.reducer;
